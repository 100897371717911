


































































































import { Component, Vue } from "vue-property-decorator";
import { course, cultivate, support } from "@/mocks/dict";

@Component
export default class School extends Vue {
  private courses = course;
  private support = support;
  private cultivate = cultivate;

  private theme = require("@/assets/images/adv/03.jpg");
  private ziran = require("@/assets/images/adv/02.png");
  private hezuo = require("@/assets/images/adv/04.jpg");
  private biaoge = require("@/assets/images/adv/05.jpg");
}
